@import './bootstrap/bootstrap';

body {
  padding-top: 56px;
}

@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  src: url('/fonts/DroidSerif-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: bold;
  src: url('/fonts/DroidSerif-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Droid Serif';
  font-style: italic;
  src: url('/fonts/DroidSerif-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Droid Serif';
  font-style: italic;
  font-weight: bold;
  src: url('/fonts/DroidSerif-BoldItalic.ttf') format('truetype');
}

.rubric-header-image-outer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.rubric-header-image-container {
  width: 400px;
  max-width: 50%;
  margin-top: 20px;
}
.rubric-header-image {
  width: 100%;
  max-width: 100%;
}

.rubric-home-button-container {
  width: 600px;
  max-width: 98%;
}

.rubric-home-buttons {
  margin-bottom: 15px;
}

.rubric-contact-form-container {
  width: 500px;
  max-width: 98%;
}

.bible-text {
  font-size: 16px;
  line-height: 28px;
}

.rubric-text-container {
  width: 600px;
  max-width: 98%;
}

.rubric-blog-tag-text {
  font-size: 13px;
}

.post-summary-container {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba($rubric-dm-text, .1);
}
